import { Controller } from 'stimulus'
import 'trix'
require('@rails/actiontext')
require('trix/dist/trix.css')
import SwalStyles from '../utils/swal_styles'

export default class extends Controller {
  // data-trix-editor-allow-attachement='false' if want to disable the attachment feature from the Trix editor.
  static targets = ['editor']
  static values = { allowAttachement: { type: Boolean, default: true } }

  connect() {
    this.editorTarget.addEventListener('trix-file-accept', function(event) {
      const acceptedTypes = ['image/jpeg', 'image/png', 'image/gif', 'application/pdf']
      if (!acceptedTypes.includes(event.file.type)) {
        event.preventDefault()
        swal.fire({
          title: window.I18n.t('js.information'),
          icon: 'warning',
          text: window.I18n.t('js.support_files_msg'),
          iconHtml: '<div class="tw-text-3xl">!</div>',
          customClass: SwalStyles,
        })
      }
    })
    this.hideAttachmentIcon()
  }

  // This will hide the attachment icon from UI and
  // also disable JS to upload files using drag-drop.
  // https://github.com/basecamp/trix/issues/604
  hideAttachmentIcon() {
    if (!this.allowAttachementValue) {
      const toolbarId = this.editorTarget.getAttribute('toolbar')
      const elements = document.getElementById(toolbarId).getElementsByClassName('trix-button-group--file-tools')
      Array.from(elements).forEach((element) => {
        element.remove()
      })
      this.editorTarget.addEventListener('trix-file-accept', function(event) {
        event.preventDefault()
      })
    }
  }
}
